import AppResponse from 'models/AppResponse';

const { EMPLOYEE_URL } = require('config/api');
const { default: ApiService } = require('./Api.service');

class EmployeeServiceClass extends ApiService {
    constructor() {
        super(EMPLOYEE_URL);
    }
}

const EmployeeService = new EmployeeServiceClass();

export default EmployeeService;
