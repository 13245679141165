// assets
import { IconListDetails, IconRowInsertBottom, IconFilePlus } from '@tabler/icons';

import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
// import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';

// constant
const icons = { IconListDetails, IconRowInsertBottom, IconFilePlus };

// ==============================|| ORDERS MENU ITEMS ||============================== //

const purchase = {
    id: 'purchase',
    type: 'group',
    title: 'Purchase',
    children: [
        {
            id: 'purchase-view',
            title: 'Purchase Orders',
            type: 'item',
            url: '/purchase',
            // icon: icons.IconListDetails,
            icon: LocalShippingOutlinedIcon,
            breadcrumbs: true
        },
        {
            id: 'puchase_order-receipt',
            title: 'Create Purchase Order',
            type: 'item',
            url: '/purchase/receipt/add',
            // icon: icons.IconFilePlus,
            icon: ReceiptLongRoundedIcon,
            breadcrumbs: true
        },
        {
            id: 'puchase_order-request',
            title: 'Create Purchase Order Request',
            type: 'item',
            url: '/purchase/request/add',
            // icon: icons.IconFilePlus,
            icon: ReceiptLongRoundedIcon,
            breadcrumbs: true
        }
    ]
};

export default purchase;
