// assets
import { IconListDetails, IconRowInsertBottom, IconFilePlus } from '@tabler/icons';

import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
// import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';

// constant
const icons = { IconListDetails, IconRowInsertBottom, IconFilePlus };

// ==============================|| ORDERS MENU ITEMS ||============================== //

const sales = {
    id: 'sales',
    type: 'group',
    title: 'Sales',
    children: [
        {
            id: 'sales-view',
            title: 'Orders',
            type: 'item',
            url: '/sales',
            // icon: icons.IconListDetails,
            icon: LocalShippingOutlinedIcon,
            breadcrumbs: true
        },
        {
            id: 'order-receipt',
            title: 'Create Sales Order',
            type: 'item',
            url: '/sales/receipt/add',
            // icon: icons.IconFilePlus,
            icon: ReceiptLongRoundedIcon,
            breadcrumbs: true
        }
    ]
};

export default sales;
