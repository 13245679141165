const { ORGANIZATION_URL } = require('config/api');
const { default: ApiService } = require('./Api.service');

class OrganizationServiceClass extends ApiService {
    constructor() {
        super(ORGANIZATION_URL);
    }
}

const OrganizationService = new OrganizationServiceClass();

export default OrganizationService;
