// assets
import { IconUsers, IconBuildingArch } from '@tabler/icons';

import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
// constant
const icons = { IconUsers, IconBuildingArch };

// ==============================|| management MENU ITEMS ||============================== //

const management = {
    id: 'management',
    type: 'group',
    title: 'Management',
    children: [
        {
            id: 'management:storages',
            title: 'Storage',
            type: 'item',
            url: '/management/storage',
            // icon: icons.IconBuildingArch,
            icon: WarehouseOutlinedIcon,
            breadcrumbs: true
        },
        {
            id: 'management:items',
            title: 'Items',
            type: 'item',
            url: '/management/items',
            // icon: icons.IconBuildingArch,
            icon: CategoryOutlinedIcon,
            breadcrumbs: true
        },
        {
            id: 'management:organization',
            title: 'Organization',
            type: 'item',
            url: '/management/organization/add',
            // icon: icons.IconBuildingArch,
            icon: CorporateFareOutlinedIcon,
            breadcrumbs: true
        }
        // {
        //     id: 'management:vendor',
        //     title: 'Vendor',
        //     type: 'item',
        //     url: '/management/vendor',
        //     // icon: icons.IconBuildingArch,
        //     icon: SellOutlinedIcon,
        //     breadcrumbs: true
        // },
        // {
        //     id: 'management:customer',
        //     title: 'Customer',
        //     type: 'item',
        //     url: '/management/customer',
        //     // icon: icons.IconBuildingArch,
        //     icon: ShoppingCartOutlinedIcon,
        //     breadcrumbs: true
        // },
        // {
        //     id: 'management:employee',
        //     title: 'Employee',
        //     type: 'item',
        //     url: '/management/employee',
        //     // icon: icons.IconBuildingArch,
        //     icon: BadgeOutlinedIcon,
        //     breadcrumbs: true
        // }
    ]
};

export default management;
