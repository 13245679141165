import dashboard from './dashboard';
// import products from './products';
import inventory from './inventory';
import orders from './orders';
import management from './management';
import purchase from './purchase';
// import pages from './pages';
// import utilities from './utilities';
// import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [
        dashboard,
        inventory,
        // orders, purchase,
        management
    ]
};

export default menuItems;
