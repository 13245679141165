// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

// action - auth reducer
export const INITLIALIZE = '@customization/INITLIALIZE';
export const LOGIN = '@customization/LOGIN';
export const REGISTER = '@customization/REGISTER';
export const LOGOUT = '@customization/LOGOUT';
export const SET_EMPLOYEE = '@customization/SET_EMPLOYEE';
export const SET_ORGANIZATION = '@customization/SET_ORGANIZATION';
