import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// Nosh Bags routing
// const Purchases = Loadable(lazy(() => import('views/Purchases/Purchases')));
const PurchaseFormView = Loadable(lazy(() => import('views/purchase/FormView')));
const PurchaseRequestFormView = Loadable(lazy(() => import('views/purchase/FormView/requestForm')));
const PurchaseView = Loadable(lazy(() => import('views/purchase/View')));
const CommingSoon = Loadable(lazy(() => import('views/status/ComingSoon')));
// ==============================|| MAIN ROUTING ||============================== //

const PurchaseRoutes = {
    path: '/purchase',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <PurchaseView />
        },
        {
            path: 'receipt/add',
            element: <PurchaseFormView />
        },
        {
            path: 'request/add',
            element: <PurchaseRequestFormView />
        }
    ]
};

export default PurchaseRoutes;
