import { applyMiddleware, createStore } from 'redux';
// eslint-disable-next-line import/no-unresolved
import thunk from 'redux-thunk';
import reducer from './reducers';

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = createStore(reducer, applyMiddleware(thunk));
const persister = 'NOSH_SHOP';

export { store, persister };
