import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// management routing

const ItemsFormView = Loadable(lazy(() => import('views/management/items/FormView')));
const ItemView = Loadable(lazy(() => import('views/management/items/View')));

const OrganizationFormView = Loadable(lazy(() => import('views/management/organization/FormView')));
const OrganizationView = Loadable(lazy(() => import('views/management/organization/View')));

const VendorFormView = Loadable(lazy(() => import('views/management/vendor/FormView')));
const VendorView = Loadable(lazy(() => import('views/management/vendor/View')));

const CustomerFormView = Loadable(lazy(() => import('views/management/customer/FormView')));
const CustomerView = Loadable(lazy(() => import('views/management/customer/View')));

const StorageFormView = Loadable(lazy(() => import('views/management/storage/FormView')));
const StorageView = Loadable(lazy(() => import('views/management/storage/View')));

const EmployeeFormView = Loadable(lazy(() => import('views/management/employee/FormView')));
const EmployeeView = Loadable(lazy(() => import('views/management/employee/View')));

// ==============================|| MAIN ROUTING ||============================== //

const managementRoutes = {
    path: '/management',
    element: <MainLayout />,
    children: [
        {
            path: '/items/add',
            element: <ItemsFormView />
        },
        {
            path: '/items',
            element: <ItemView />
        },
        {
            path: '/items/edit/:id',
            element: <ItemsFormView />
        },
        {
            path: '/storage/add',
            element: <StorageFormView />
        },
        {
            path: '/storage/edit/:id',
            element: <StorageFormView />
        },
        {
            path: '/storage',
            element: <StorageView />
        },

        // {
        //     path: '/organization/add',
        //     element: <OrganizationFormView />
        // },
        {
            path: '/organization/edit/:id',
            element: <OrganizationFormView />
        },
        {
            path: '/organization/add',
            element: <OrganizationFormView />
        },

        {
            path: '/vendor/add',
            element: <VendorFormView />
        },
        {
            path: '/vendor/edit/:id',
            element: <VendorFormView />
        },
        {
            path: '/vendor',
            element: <VendorView />
        },

        {
            path: '/customer/add',
            element: <CustomerFormView />
        },
        {
            path: '/customer/edit/:id',
            element: <CustomerFormView />
        },
        {
            path: '/customer',
            element: <CustomerView />
        },

        {
            path: '/employee/add',
            element: <EmployeeFormView />
        },
        {
            path: '/employee/edit/:id',
            element: <EmployeeFormView />
        },
        {
            path: '/employee',
            element: <EmployeeView />
        }
    ]
};

export default managementRoutes;
