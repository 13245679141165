// project imports
import EmployeeService from 'services/Employee.service';
import OrganizationService from 'services/Organization.service';

// action - state management
import * as actionTypes from '../actions';

export const initialState = {
    organization: null
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const orgReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ORGANIZATION:
            return {
                ...state,
                organization: action.payload.data?.organization || action.payload.data?._id
            };

        default:
            return state;
    }
};
export const newOrg = (data) => async (dispatch) => {
    try {
        dispatch({
            type: actionTypes.SET_ORGANIZATION,
            payload: { data }
        });
    } catch (error) {
        console.log(error);
    }
};

export default orgReducer;
