import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// Nosh Bags routing
const NoshBags = Loadable(lazy(() => import('views/noshBags/NoshBags')));
const CommingSoon = Loadable(lazy(() => import('views/status/ComingSoon')));
const InventoryView = Loadable(lazy(() => import('views/inventory/Views')));
const InventoryUpdateFormView = Loadable(lazy(() => import('views/inventory/FormView')));

// ==============================|| MAIN ROUTING ||============================== //

const inventoryRoutes = {
    path: '/stock',
    element: <MainLayout />,
    children: [
        {
            path: '',
            element: <InventoryView />
        },
        {
            path: '/purchase',
            element: <InventoryUpdateFormView />
        }
    ]
};

export default inventoryRoutes;
