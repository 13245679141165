// assets
import { IconDashboard } from '@tabler/icons';

import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    type: 'group',
    children: [
        {
            id: 'dashboard-default',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            // icon: icons.IconDashboard,
            icon: DashboardOutlinedIcon,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
