import { lazy } from 'react';

// project imports

import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// Nosh Bags routing
const NoshBags = Loadable(lazy(() => import('views/noshBags/NoshBags')));
const CommingSoon = Loadable(lazy(() => import('views/status/ComingSoon')));
const InventoryView = Loadable(lazy(() => import('views/inventory/Views')));
const InventoryUpdateFormView = Loadable(lazy(() => import('views/inventory/FormView')));
const OrganizationFormView = Loadable(lazy(() => import('views/management/organization/FormView')));

// ==============================|| MAIN ROUTING ||============================== //

const onboardingRoutes = {
    path: '/organization/onboarding',
    element: <MinimalLayout />,
    children: [
        {
            path: '',
            element: <OrganizationFormView />
        }
    ]
};

export default onboardingRoutes;
