// project imports

import EmployeeService from 'services/Employee.service';

// action - state management
import * as actionTypes from '../actions';
import { newOrg } from './orgReducer';

export const initialState = {
    isAuthenticated: false, // for active default menu
    user: null,
    employee: null
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

// action creator
const setEmployee = (employee) => ({
    type: actionTypes.SET_EMPLOYEE,
    payload: { employee }
});

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INITLIALIZE:
            return {
                ...state,
                isAuthenticated: action.payload.isAuthenticated,
                isInitialized: true,
                user: action.payload.user
            };
        case actionTypes.LOGIN:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user
            };
        case actionTypes.SET_EMPLOYEE:
            return {
                ...state,
                employee: action.payload.employee
            };

        case actionTypes.REGISTER:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user
            };
        case actionTypes.LOGOUT:
            return {
                ...state,
                isAuthenticated: false,
                user: null
            };
        default:
            return state;
    }
};

export const login = (user) => async (dispatch) => {
    try {
        dispatch({
            type: actionTypes.LOGIN,
            payload: { user }
        });

        const { data, message, success } = await EmployeeService.get({});
        if (!success) throw new Error(message);

        const employee = data.find((emp) => emp.userID === user._id);

        dispatch(setEmployee(employee));
        dispatch(newOrg(employee));
    } catch (error) {
        console.log(error);
    }
};

export default authReducer;
