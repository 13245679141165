import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import NoshBagRoutes from './NoshBagRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import OrderRoutes from './OrderRoutes';
import inventoryRoutes from './inventory.routes';
import managementRoutes from './management.routes';
import PurchaseRoutes from './purchaseRoutes';
import onboardingRoutes from './onboardingRoutes';
// import config from 'config';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([
        AuthenticationRoutes,
        MainRoutes,
        NoshBagRoutes,
        OrderRoutes,
        onboardingRoutes,
        inventoryRoutes,
        PurchaseRoutes,
        managementRoutes
    ]);
}
