// assets
import { IconBuildingWarehouse, IconApps } from '@tabler/icons';

import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';

// constant
const icons = { IconBuildingWarehouse, IconApps };

// ==============================|| inventory MENU ITEMS ||============================== //

const inventory = {
    id: 'inventory',
    type: 'group',
    title: 'Inventory',
    children: [
        {
            id: 'inventory:stock',
            title: 'Stock',
            type: 'item',
            url: '/stock',
            // icon: icons.IconBuildingWarehouse,
            icon: Inventory2OutlinedIcon,
            breadcrumbs: true
        },
        {
            id: 'inventory:purchase',
            title: 'Update Stock',
            type: 'item',
            url: '/stock/purchase',
            // icon: icons.IconApps,
            icon: RequestQuoteOutlinedIcon,
            breadcrumbs: true
        }
    ]
};

export default inventory;
