const BASE_URL = 'https://api.nosh.tech/api';
const NW_BASE_URL = 'https://pxmndq9rhi.execute-api.eu-west-1.amazonaws.com/dev';

// AUTH APIS
export const AUTH_URL = `${BASE_URL}/auth`;
export const LOGIN_URL = `${AUTH_URL}/login`;

// APPLICATION APIS
export const ORGANIZATION_URL = `${NW_BASE_URL}/organization`;
export const VENDOR_URL = `${NW_BASE_URL}/vendor`;
export const EMPLOYEE_URL = `${NW_BASE_URL}/employee`;
export const STORAGE_URL = `${NW_BASE_URL}/storage`;
export const CUSTOMER_URL = `${NW_BASE_URL}/customer`;
export const PURCHASE_ORDER_URL = `${NW_BASE_URL}/purchase-order`;
export const PURCHASE_ORDER_CHANGE_STATUS_URL = `${NW_BASE_URL}/purchase-order/change-status`;
export const PURCHASE_ORDER_REQUEST_URL = `${NW_BASE_URL}/purchase-order-request`;
export const PURCHASE_HISTORY_URL = `${NW_BASE_URL}/purchase-order/history`;
export const PURCHASE_ORDER_EXCESS_CHECK_URL = `${NW_BASE_URL}/purchase-order-request-check-excess-purchase`;
export const SALE_ORDER_URL = `${NW_BASE_URL}/sale-order`;
export const SALE_ORDER_CHANGE_STATUS_URL = `${NW_BASE_URL}/sale-order/change-status`;
export const SALE_ANALYTICS_URL = `${NW_BASE_URL}/sale-order/sales-analysis`;
// export const ITEM_URL = `${NW_BASE_URL}/item`;
export const ITEM_URL = `http://localhost:9090/dev/item`;
export const INVENTORY_URL = `${NW_BASE_URL}/inventory`;
export const INVENTORY_MONITORING_URL = `${NW_BASE_URL}/inventory/monitoring`;
